import NotFoundIcon from "@/assets/notfound.svg?react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center pt-20">
      <div className="w-[503px] inline-flex flex-col justify-start items-center gap-12 ">
        <div className="self-stretch flex flex-col justify-start items-center gap-16">
          <NotFoundIcon className="w-96 h-32" />
          <div className="self-stretch flex flex-col justify-start items-center gap-7">
            <div className="self-stretch text-center justify-start text-zinc-900 text-2xl font-semibold font-['Pretendard']">
              페이지가 없거나 잘못된 경로입니다.
            </div>
            <div className="text-center justify-start text-zinc-900 text-[17px] font-normal font-['Pretendard'] leading-loose">
              경로를 확인하시고 다시 이용해주시기 바랍니다.
              <br />
              이용에 불편을 드려서 죄송합니다.
            </div>
          </div>
        </div>
        <Link
          to={"/"}
          className="px-4 py-2 bg-stone-50 rounded-lg outline outline-1 outline-offset-[-1px] outline-zinc-900 inline-flex justify-center items-center"
        >
          <div className="text-center justify-start text-zinc-900 text-lg font-medium font-['Pretendard'] leading-loose">
            홈으로 돌아가기
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
